<template>
  <div class="orderDetails">
    <van-nav-bar
      :title="title"
      left-arrow
      fixed
      @click-left="onClickLeft"
    ></van-nav-bar>

    <div class="ticketDiv">
      <div class="ticket1">
        <img :src="productInfo.img || ticketImg" />
        <div>
          <div class="title1">
            {{ productInfo.productName }}
          </div>
          <div class="title2">
            {{ productInfo.priceTypeName }} / {{ productInfo.count
            }}{{ getTicket(productInfo.count)}}
          </div>
          <div class="title2">{{ $t('app.non_refundable') }}</div>
        </div>
      </div>
      <div class="ticketDate">{{ productInfo.usingDate }}</div>

      <div style="display: flex; justify-content: space-between">
        <div>{{ $t("app.total") }}</div>
        <div style="font-weight: 600">{{ $t('app.money') }}{{ productInfo.price }}</div>
      </div>
    </div>

    <div class="bodyDiv">
      <div class="title">{{ $t("app.Contact_information") }}</div>
      <div class="infoDiv">
        <div class="infoType">{{ $t("app.name") }}</div>
        <div class="infoValue">{{ name }}</div>
      </div>
      <div class="infoDiv">
        <div class="infoType">{{ $t("app.eMail") }}</div>
        <div class="infoValue">{{ eMail }}</div>
      </div>
    </div>

    <div class="allIdsDiv">
      <div class="title">{{ $t("app.More_Detail") }}</div>
      <div class="infoDiv">
        <div class="infoType">{{ $t("app.System_Order_ID") }}</div>
        <div class="infoValue">{{ System_Order_ID }}</div>
      </div>
      <div class="infoDiv">
        <div class="infoType">{{ $t("app.Payment_Order_ID") }}</div>
        <div class="infoValue">{{ Payment_Order_ID }}</div>
      </div>
      <div class="infoDiv">
        <div class="infoType">{{ $t("app.Ticket_Order_ID") }}</div>
        <div class="infoValue">{{ Ticket_Order_ID }}</div>
      </div>
      <div class="infoDiv">
        <div class="infoType">{{ $t("app.System_Order_Status") }}</div>
        <div class="infoValue">{{ System_Order_Status }}</div>
      </div>
      <div class="infoDiv">
        <div class="infoType">{{ $t("app.Ticket_PDF_Url") }}</div>
        <div class="infoValue">{{ Ticket_PDF_Url }}</div>
      </div>
    </div>

    <!-- <contact-us></contact-us> -->
  </div>
</template>

<script>
import apis from "../../utils/apis";
import ContactUs from "../../components/contactUs";
import { NavBar } from "vant";
import Vue from "vue";

Vue.use(NavBar);

let { login } = apis;
let { OrderDetail } = apis;

export default {
  components: { ContactUs },

  data() {
    return {
      title: this.$t("app.OrderDetails"),

      ticketImg:
        "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/27/79/12/10/caption.jpg?w=600&h=-1&s=1",

      name: "",
      eMail: "",
      System_Order_ID: "",
      Payment_Order_ID: "",
      Ticket_Order_ID: "",
      System_Order_Status: "",
      Ticket_PDF_Url: "",

      payUrl: "",
      ticketDetail: {},
      orderId: "",
      productInfo: {},
      orderInfo: {},
    };
  },

  created() {
    // console.log(this.$route.query.ticketDetail, "----999----");

    // this.payUrl = decodeURIComponent(this.$route.query.payUrl)

    // this.ticketDetail = JSON.parse(this.$route.query.ticketDetail)

    this.orderId = this.$route.query.orderId;
    // this.eMail = this.$route.query.email
    this.getOrderDetails();
  },

  methods: {
    getTicket(num){
      if (!isNaN(num)) {
        return Number(num) > 1 ?  this.$t('app.tickets_s') : this.$t('app.tickets')
      } else {
        return this.$t('app.tickets');
      }
    },
    getOrderDetails() {
      let params = {
        mail: localStorage.getItem("email"),
        orderId: this.orderId,
      };
      OrderDetail({ ...params }, (data) => {
        this.orderInfo = data.data.orderInfo;
        this.productInfo = data.data.productInfo;

        this.Ticket_Order_ID = this.productInfo.easygoId;
        this.name = this.productInfo.contectUserInfo.userName;
        this.eMail = this.productInfo.contectUserInfo.email;

        this.System_Order_ID = this.productInfo.orderId;
        this.Payment_Order_ID = this.productInfo.qfpayId;
        this.Ticket_Order_ID = this.productInfo.easygoId;
        this.System_Order_Status =
          this.orderInfo.orderStatus == 1
            ? this.$t("app.Success")
            : this.orderInfo.orderStatus == 2
            ? this.$t("app.Failure")
            : this.orderInfo.orderStatus == 3
            ? this.$t("app.Processing")
            : this.$t("app.Processing");
        this.Ticket_PDF_Url = this.productInfo.pdfurl;
      });
    },

    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .van-nav-bar__content {
  height: 1rem;
}

/deep/ .van-icon {
  color: black;
  font-size: 0.36rem;
}

/deep/ .van-nav-bar__title {
  font-weight: 700;
}

.orderDetails {
  background: #f8f8f8;
  height: 100vh;
  padding-top: 1rem;
  font-size: 0.26rem;

  .ticketDiv {
    //display: flex;
    background: white;
    padding: 0.3rem 0.5rem 0.5rem 0.5rem;

    .ticket1 {
      display: flex;
      text-align: left;
      width: 100%;
      align-items: center;

      img {
        border-radius: 0.08rem;
        height: 1rem;
        width: 1.33rem;
        margin-right: 0.2rem;
      }

      .title1 {
        color: black;
        font-weight: 500;
        font-size: 0.3rem;
      }

      .title2 {
        color: #9a9a9a;
        font-size: 0.26rem;
      }
    }

    .ticketDate {
      font-weight: 600;
      text-align: left;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .allIdsDiv {
    padding: 0.4rem;
    background: #ffffff;
    align-items: start;
    font-size: 0.26rem;
    margin-top: 0.34rem;
    text-align: left;

    .title {
      font-size: 0.36rem;
    }

    .infoDiv {
      margin-top: 0.4rem;

      .infoValue {
        margin-top: 0.2rem;
      }
    }
  }

  .payBtn {
    margin-top: 2rem;
    background: #f2b203;
    color: black;
    width: 6rem;
    height: 1rem;
    line-height: 1rem;
    border-radius: 0.2rem;
    margin-left: 0.75rem;
    margin-bottom: 0.56rem;
    text-align: center;
    font-weight: 700;
    font-size: 0.46rem;
  }

  .bodyDiv {
    padding: 0.4rem;
    background: #ffffff;
    align-items: start;
    font-size: 0.26rem;
    text-align: left;
    margin-top: 0.34rem;

    .title {
      font-size: 0.36rem;
    }

    .infoDiv {
      display: flex;
      padding: 0.1rem 0;

      .infoType {
        width: 30%;
      }
    }
  }
}
</style>
